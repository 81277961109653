import { axios } from "@/plugins/axios";
export function getRid(data) {
  return axios.request({
    url: "/api/Publics/getDownlUrl",
    method: "POST",
    params: {
      ...data
    }
  });
}
