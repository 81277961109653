import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "@/plugins/axios";
import "lib-flexible/flexible";
import "normalize.css/normalize.css"; //样式初始化模块
import "vant/lib/index.css";
import VueClipboard from "vue-clipboard2";
import LuckDraw from "vue-luck-draw";
Vue.use(LuckDraw);
Vue.use(VueClipboard);
Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
