<template>
  <div class="home">
    <div>
      <img src="@/assets/image/text.png" class="text-bgc" mode="" />
    </div>
    <div class="btn-box">
      <LuckyWheel
        ref="LuckyWheel"
        class="luck"
        :blocks="blocks"
        :prizes="prizes"
        :buttons="buttons"
        @start="startCallback"
        @end="endCallback"
      />
      <div class="btn" @click="Download"></div>
      <p class="text_info">
        <span>
          您还拥有<span class="span_2">{{ num }}次</span>抽奖机会</span
        >
      </p>
    </div>
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <img src="@/assets/image/openImg.png" class="block" alt="" />
      </div>
    </van-overlay>
    <van-popup
      v-model="winning"
      round
      class="popup"
      closeable
      :close-on-click-overlay="false"
    >
      <div class="content">
        <p class="title">恭喜抽中<span>18.8元现金红包</span></p>
        <div>
          <img src="@/assets/image/load.png" class="images" @click="Download" />
        </div>
        <p class="load-info">请先下载秀喵喵领取红包</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getRid } from "@/api/home";
export default {
  name: "Home",
  data() {
    return {
      browser: { ua: {} },
      show: false,
      winning: false,
      DownloadUrl: null,
      num: 0,
      prizes: [
        {
          imgs: [
            {
              src: require("@/assets/image/10.png"),
              width: "35px",
              height: "45px",
              top: "39px"
            }
          ]
        },
        {
          imgs: [
            {
              src: require("@/assets/image/18.png"),
              width: "35px",
              height: "45px",
              top: "39px"
            }
          ]
        },
        {
          imgs: [
            {
              src: require("@/assets/image/20.png"),
              width: "35px",
              height: "45px",
              top: "39px"
            }
          ]
        },
        {
          imgs: [
            {
              src: require("@/assets/image/50.png"),
              width: "35px",
              height: "45px",
              top: "39px"
            }
          ]
        },
        {
          imgs: [
            {
              src: require("@/assets/image/100.png"),
              width: "35px",
              height: "45px",
              top: "39px"
            }
          ]
        },
        {
          imgs: [
            {
              src: require("@/assets/image/3.png"),
              width: "53px",
              height: "40px",
              top: "39px"
            }
          ]
        }
      ],
      blocks: [
        {
          // padding:'10px',
          imgs: [
            {
              src: require("@/assets/image/neibu.png"),
              width: "83.5%",
              height: "83.5%",
              rotate: true,
              top: "21px"
            }
          ]
        }
      ],
      buttons: [
        { radius: "33px", background: "#fe3815" },
        { radius: "28px", background: "#fcf150" },
        {
          radius: "23px",
          background: "#fdd71e",
          pointer: true,
          fonts: [
            {
              text: "点击\n抽奖",
              fontSize: "13px",
              fontWeight: "600",
              fontColor: "#fe3815",
              top: "-16px"
            }
          ]
        }
      ]
    };
  },
  created() {
    this.getQuDao();
    this.browser.ua = this.getBrowserInformation();
    document.title = "秀喵喵";
  },
  computed: {
    isIos() {
      if (
        this.browser.ua.iOS ||
        this.browser.ua.iPhone ||
        this.browser.ua.iPad
      ) {
        return true;
      }
      return false;
    },
    isAndroid() {
      if (this.browser.ua.android) {
        return true;
      }
      return false;
    }
  },
  methods: {
    startCallback() {
      if (this.num == 0) {
        this.$toast("暂无抽奖次数");
        return;
      }
      this.num = 0;
      localStorage.num = 0;
      this.$refs.LuckyWheel.play();
      setTimeout(() => {
        this.$refs.LuckyWheel.stop(1);
      }, 100);
    },
    endCallback(prize) {
      console.warn(prize);
      this.winning = true;
    },
    async getQuDao() {
      if (window.localStorage.num == undefined) {
        window.localStorage.num = 1;
      }
      this.num = window.localStorage.num;
      let rid = this.$route.query.rid || "";
      const res = await getRid({ rid: rid });
      if (res.data.code == 200) {
        this.DownloadUrl = res.data.data.url;
      }
      console.log(res.data.data.url, "2111");
    },
    Download() {
      if (this.isIos) {
        this.$toast("苹果用户暂未上架!敬请期待");
        return;
      }
      if (this.browser.ua.weChat) {
        this.winning = false;
        this.show = true;
        return;
      }
      if (!this.$route.query.code) {
        console.log(this.$route.query.rid);
        window.location.href = this.DownloadUrl;
        return;
      }
      this.$copyText(this.$route.query.code).then(e => {
        console.log(e);
        window.location.href = this.DownloadUrl;
      });
    },
    getBrowserInformation() {
      let u = navigator.userAgent;
      return {
        //移动终端浏览器版本信息
        trident: u.indexOf("Trident") > -1, //IE内核
        presto: u.indexOf("Presto") > -1, //opera内核
        webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
        gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        iOS: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
        iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf("iPad") > -1, //是否iPad
        webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
        weChat: u.indexOf("MicroMessenger") > -1, //是否微信
        mac: u.indexOf("Mac OS X") > 0
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  height: 100vh;
  background: url("./../assets/image/bossBgc.png") no-repeat;
  background-size: 100%;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .text-bgc {
    width: 510px;
    height: 250px;
    margin-top: 30px;
  }
  .btn-box {
    margin-top: 20px;
    height: 520px;
    width: 520px;
    .luck {
      width: 520px;
      height: 520px;
      background: url("./../assets/image/zhuanpan.png") center center no-repeat;
      background-position: center;
      background-size: contain;
    }
    .btn {
      width: 393px;
      height: 108px;
      background: url("./../assets/image/download.png") center center no-repeat;
      background-size: 100%;
      margin: auto;
      margin-top: 35px;
    }
  }
}
.wrapper {
  display: flex;
  // align-items: ;
  justify-content: flex-end;
}

.block {
  width: 450px;
  flex-shrink: 0;
  margin-right: 30px;
}
.text_info {
  width: 320px;
  height: 45px;
  text-align: center;
  font-size: 28px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.5);
  color: #ffffff;
  border-radius: 60px;
  margin-top: 14px;
  span {
    color: #ffffff;
    .span_2 {
      color: #ff2501;
    }
  }
}
.popup {
  width: 750px;
  height: 840px;
  background: url("./../assets/image/pop.png") no-repeat;
  background-position: center;
  background-size: contain;
  .content {
    width: 336px;
    height: 240px;
    margin: 480px 0 0 222px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .images {
    width: 190px;
    height: auto;
  }
  .title {
    font-size: 29px;
    color: #ffffff;
    span {
      color: #ffde00;
    }
  }
  .load-info {
    font-size: 22px;
    color: #ffffff;
  }
}
</style>
