import Vue from "vue";
import axios from "axios";
// import { Toast } from "vant";
// import router from "./../router";
// 环境的切换
axios.defaults.baseURL =
  process.env.NODE_ENV == "development"
    ? "http://api.xmm.ikuyoo.cn"
    : "https://api.xmm.yhaocang.net";
// 请求超时时间
axios.defaults.timeout = 60000;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
// 请求拦截器

axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // config.params.store_id = 1;
    return config;
  },
  error => {
    return Promise.error(error);
  }
);
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);
Vue.prototype.$axios = axios;
export { axios };
